@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

.ant-layout {
  -webkit-font-smoothing: antialiased;
}
.title {
  color: #A52B2A;
  font-size: 50px !important;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  text-align: center;
}
.modal-title {
  color: #A52B2A;
  font-size: 30px !important;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}
.rubik {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}
body {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  background-color: white !important;
}

.header {
  position: 'fixed';
  z-index: 1;
  width: '100%';
  height: 'auto';
  border-radius: '0 0 50px 50px';
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover, .hiddenButtonStytle:hover, .hiddenButtonStytle:focus {
  background-color: transparent;
}


.container{
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2.5rem;
}

.hiddenButtonStytle {
  position: absolute;
  z-index: 1; 
  width: 10%;
  height: 6%;
  border: none;
  background-color: transparent;
}

.white-text {
  color: #fff !important;
}

.footer-link {
  text-decoration: none;
  width: 100%;
}

.paragraph {
  text-align: center;
}
.promotion-carousel .slick-slide {
  padding: 0 15px;
  box-sizing: border-box;
}

.promotion-carousel>.slick-list {
  margin: 0 -15px;
}

.text-justify {
  text-align: justify;
  /* text-justify: inter-word; */
  word-spacing:-2px;
}

.promotions>.ant-card-cover>img {
  border-radius: 20px 20px 0px 0px;
}

.ant-card.promotions {
  border-radius: 20px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -42px;
}

.ant-carousel .slick-dots li button {
  background: #8c1510;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #720702;
}

ol.privacy-list li {
  margin-bottom: 10px; /* Adjust the value to set your desired space */
}

.ant-drawer-title, .ant-drawer-close {
  color: white;
}

.mobile-drawer>.ant-menu-item-selected {
  background-color: rgb(61, 0, 0) !important;
}
/* 
.center-img {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-image {
  position: relative;
  height: 100%;
} */
/* .promotions {

  background: transparent !important;
}
.promotions>.ant-card-body {
  background-color: white;
} */
/* .slick-track {
  margin: 0 -15px;
} */
/* .slick-next {
  right: 10px !important;
}
.slick-prev {
  left: 10px !important;
} */
@media only screen and (min-width: 576px) {
  .container {max-width: 576px;}
  .title {
    text-align: center !important;
  }
  .text-center-mobile {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .container {max-width: 768px;
  
    padding: 0 6rem;}
  .title {
    text-align: left !important;
  }
  .paragraph {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
    padding: 0 6rem;
  }
  .title {
    text-align: left !important;
  }
  .paragraph {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding: 0 6rem;}
  .title {
    text-align: left !important;
  }
  .paragraph {
    text-align: left;
  }
}